

import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';


Raven
  .config("", {
    whitelistUrls: [
        "https://www.lex4you.ch",
    ],
    release: "3.12.",
    environment: "production"
  })
  .addPlugin(RavenVue, Vue)
  .install();

if (window.APP && window.APP.user) {
  Raven.setUserContext({
      id: window.APP.user.id,
      email: window.APP.user.email
  });
}

if (window.APP && window.APP.lex_user) {
  Raven.setUserContext({
      id: window.APP.lex_user.id,
      email: window.APP.lex_user.email
  });
}
